import React from "react";
import HotDropsSlider from "./HotDropsSlider";

const HotDrops = () => {
  return (
    <section id="collections" className="hot-collection overflow-hidden ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center">
              <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                <h2 className="mb-0">Featured NFT Drops</h2>
              </div>
              <p>
                Discover the latest and most exciting NFT releases on Polygon.
              </p>
            </div>
          </div>
        </div>
        <div className="hot-collection-content">
          <div className="row">
            <div className="col-lg-6">
              <HotDropsSlider />
            </div>
            <div className="col-lg-6">
              <div className="row g-4 justify-content-center">
                <div className="col-sm-6">
                  <a style={{ cursor: "pointer" }} onClick={() => window.open("https://launchpad.heymint.xyz/mint/evil-frens", "_blank")}>
                    <div className="nft-hc-item">
                      <div className="thumb-wrapper">
                          <img
                            src="assets/img/home2/evil-frens.png"
                            className="img-fluid"
                            alt="item-thumb"
                          />
                      </div>
                      <div className="hc-item-title">
                        <h6>Evil Frens</h6>
                      </div>
                      <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                        <div>
                          <span>Mint Price</span>
                          <h6>1.5 POL</h6>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="hc-item-author-info d-flex align-items-center">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="author-title">
                          <img style={{ width: "45%" }} src="assets/img/heymint-logo.webp"/>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6">
                  <a style={{ cursor: "pointer" }} onClick={() => window.open("https://launchpad.heymint.xyz/mint/dumpling-xo", "_blank")}>
                    <div className="nft-hc-item">
                      <div className="thumb-wrapper">
                          <img
                            src="assets/img/home2/dumplings.png"
                            className="img-fluid"
                            alt="item-thumb"
                          />
                      </div>
                      <div className="hc-item-title">
                        <h6>Dumplings XO</h6>
                      </div>
                      <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                        <div>
                          <span>Mint Price</span>
                          <h6>2.1 POL</h6>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="hc-item-author-info d-flex align-items-center">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="author-title">
                          <img style={{ width: "45%" }} src="assets/img/heymint-logo.webp"/>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6">
                  <a style={{ cursor: "pointer" }} onClick={() => window.open("https://launchpad.heymint.xyz/mint/demon-desire", "_blank")}>
                    <div className="nft-hc-item">
                      <div className="thumb-wrapper">
                          <img
                            src="assets/img/home2/demon-deseire-f.png"
                            className="img-fluid"
                            alt="item-thumb"
                          />
                      </div>
                      <div className="hc-item-title">
                        <h6>Demon Desire</h6>
                      </div>
                      <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                        <div>
                          <span>Mint Price</span>
                          <h6>1.5 POL</h6>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="hc-item-author-info d-flex align-items-center">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="author-title">
                          <img style={{ width: "45%" }} src="assets/img/heymint-logo.webp"/>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-sm-6">
                  <a style={{ cursor: "pointer" }} onClick={() => window.open("https://launchpad.heymint.xyz/mint/moon-minters", "_blank")}>
                    <div className="nft-hc-item">
                      <div className="thumb-wrapper">
                          <img
                            src="assets/img/home2/moon-minter.png"
                            className="img-fluid"
                            alt="item-thumb"
                          />
                      </div>
                      <div className="hc-item-title">
                        <h6>Moon Minters</h6>
                      </div>
                      <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                        <div>
                          <span>Mint Price</span>
                          <h6>1.5 POL</h6>
                        </div>
                      </div>
                      <div className="spacer"></div>
                      <div className="hc-item-author-info d-flex align-items-center">
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="author-title">
                          <img style={{ width: "45%" }} src="assets/img/heymint-logo.webp"/>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotDrops;
