import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Web3Button, lightTheme, useChainId } from "@thirdweb-dev/react";
import { Navigation } from "swiper";
import { ethers } from "ethers";
import ErrorMessagePopup from './popups/ErrorMessagePopup';
import SuccessMessagePopup from './popups/SuccessMessagePopup';
import "swiper/css/navigation";

export default function HotDropsSlider() {
  const swiperOptions = {
    slidesPerView: 1,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <Swiper
        className="nft2-hc-slider"
        modules={[Navigation]}
        {...swiperOptions}
      >
        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/tlaco-genesis.webp"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">Tlacololeros: Guardians of the land</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/tlaco-profile.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>Tlacololeros NFT</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/tlaco-token.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Ecosystem Token</span>
                        <h6>$TLACO</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Mint Price</span>
                      <h4>0.005 ETH</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Network</span>
                      <h4>Base</h4>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="secondary-btn">
                    <a href="https://tlacololeros.art/#mint" target="_blank" rel="noopener noreferrer">
                      Mint Now
                    </a>
                  </div>
                  <div className="secondary-btn ms-3">
                    <a href="https://opensea.io/collection/tlacololeros" target="_blank" rel="noopener noreferrer">
                      View Items
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/goblin-saga-genesis.gif"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">Goblin Saga</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-1.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>Goblin Saga</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/creator-profile-2.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Ecosystem Token</span>
                        <h6>$GSA</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Mint Price</span>
                      <h4>9.0 POL</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Network</span>
                      <h4>Polygon</h4>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Web3Button
                      connectWallet={{
                        btnTitle: "Mint Now",
                        modalTitle: "Evil Kongs",
                        modalSize: "compact",
                        modalTitleIconUrl: "/assets/img/fabicon.png",
                        showThirdwebBranding: false,
                      }}
                      theme={lightTheme({
                        colors: {
                          modalBg: "#1c1f26",
                          borderColor: "transparent",
                          separatorLine: "#282b2f",
                          secondaryText: "#c4c4c4",
                          primaryText: "#ffffff",
                          connectedButtonBg: "transparent",
                          primaryButtonBg: "#1c1f26",
                          primaryButtonText: "#ffffff",
                          secondaryButtonHoverBg: "#282b2f",
                          connectedButtonBgHover: "transparent",
                          walletSelectorButtonHoverBg:
                            "#282b2f",
                          secondaryButtonText: "#ffffff",
                          secondaryButtonBg: "#282b2f",
                        },
                      })}
                      contractAddress="0x4Ac03107603F37AD24a36c32bEC98b22AF46ABbf"
                      action={async (contract) => {
                        await contract.erc721.claim(1);
                      }}
                      style={{ border: "1px solid grey", borderRadius: "5px", width: "150px" }}
                    >
                      Mint Goblin
                    </Web3Button>
                  </div>
                  <div className="secondary-btn ms-3">
                    <a href="https://market.goblinsaga.xyz" target="_blank" rel="noopener noreferrer">
                      View Items
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/epop-genesis.gif"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">Epop Girls</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/epop-40x40.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>Epop Girls NFT</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/epop-token-40x40.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Ecosystem Token</span>
                        <h6>$EPOP</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Mint Price</span>
                      <h4>5.0 POL</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Network</span>
                      <h4>Polygon</h4>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Web3Button
                      connectWallet={{
                        btnTitle: "Mint Now",
                        modalTitle: "Evil Kongs",
                        modalSize: "compact",
                        modalTitleIconUrl: "/assets/img/fabicon.png",
                        showThirdwebBranding: false,
                      }}
                      theme={lightTheme({
                        colors: {
                          modalBg: "#1c1f26",
                          borderColor: "transparent",
                          separatorLine: "#282b2f",
                          secondaryText: "#c4c4c4",
                          primaryText: "#ffffff",
                          connectedButtonBg: "transparent",
                          primaryButtonBg: "#1c1f26",
                          primaryButtonText: "#ffffff",
                          secondaryButtonHoverBg: "#282b2f",
                          connectedButtonBgHover: "transparent",
                          walletSelectorButtonHoverBg:
                            "#282b2f",
                          secondaryButtonText: "#ffffff",
                          secondaryButtonBg: "#282b2f",
                        },
                      })}
                      contractAddress="0x6a91E0E4a6236008B778Ced5C9356C05f6c61e3c"
                      action={async (contract) => {
                        await contract.erc721.claim(1);
                      }}
                      style={{ border: "1px solid grey", borderRadius: "5px", width: "150px" }}
                    >
                      Mint Epop
                    </Web3Button>
                  </div>
                  <div className="secondary-btn ms-3">
                    <a href="https://market.epopgirls.xyz" target="_blank" rel="noopener noreferrer">
                      View Items
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="nft2-hc-slide-single">
            <div className="top-img">
              <img
                src="assets/img/home2/gegods-genesis.gif"
                className="img-fluid rounded-custom w-100"
                alt="image not found"
              />
            </div>
            <div className="hc-slide-info">
              <div className="slide-info-content">
                <div className="slide-title">
                  <h3 className="h4">GeJods</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/GeGods.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Creators</span>
                        <h6>GeJods</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-thumb">
                        <img
                          src="assets/img/home2/GeGods.png"
                          className="img-fluid rounded-circle"
                          alt="item-thumb"
                        />
                      </div>
                      <div className="author-title">
                        <span>Ecosystem Token</span>
                        <h6>N/A</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="bid-amount">
                      <span>Mint Price</span>
                      <h4>0.15 POL</h4>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="auction-bid bid-amount">
                      <span>Network</span>
                      <h4>Polygon</h4>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center collection-action-btns">
                  <div className="primary-btn">
                    <Web3Button
                      connectWallet={{
                        btnTitle: "Mint Now",
                        modalTitle: "Evil Kongs",
                        modalSize: "compact",
                        modalTitleIconUrl: "/assets/img/fabicon.png",
                        showThirdwebBranding: false,
                      }}
                      theme={lightTheme({
                        colors: {
                          modalBg: "#1c1f26",
                          borderColor: "transparent",
                          separatorLine: "#282b2f",
                          secondaryText: "#c4c4c4",
                          primaryText: "#ffffff",
                          connectedButtonBg: "transparent",
                          primaryButtonBg: "#1c1f26",
                          primaryButtonText: "#ffffff",
                          secondaryButtonHoverBg: "#282b2f",
                          connectedButtonBgHover: "transparent",
                          walletSelectorButtonHoverBg: "#282b2f",
                          secondaryButtonText: "#ffffff",
                          secondaryButtonBg: "#282b2f",
                        },
                      })}
                      contractAddress="0x5806e09540E10057f2f10bDb84aCFd7457D02324"
                      action={async (contract) => {
                        try {
                          const qty = 1; // Cantidad a mintear
                          const proof = []; // Prueba vacía
                          const price = ethers.utils.parseUnits("0.15", "ether"); // Ajusta el precio según el costo real del minting

                          // Llamamos a la función "mint" con ambos parámetros y el valor necesario
                          const tx = await contract.call("mint", [qty, proof], {
                            value: price, // El valor necesario para realizar el minting
                          });
                          console.log("Mint realizado exitosamente:", tx);
                        } catch (error) {
                          console.error("Error al hacer mint:", error);
                        }
                      }}
                      style={{ border: "1px solid grey", borderRadius: "5px", width: "150px" }}
                    >
                      Mint GeJods
                    </Web3Button>
                  </div>
                  <div className="secondary-btn ms-3">
                    <a href="https://opensea.io/collection/gejod" target="_blank" rel="noopener noreferrer">
                      View Items
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
      <div className="swiper-nav-control custom-control">
        <span className="swiper-button-next">
          <i className="fa-solid fa-arrow-left"></i>
        </span>
        <span className="swiper-button-prev">
          <i className="fa-solid fa-arrow-right"></i>
        </span>
      </div>

      {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
      {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
    </>
  );
}

const counter = ({ days, hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li>
        <h4 className="days">{days}</h4>
        <span>Days</span>
      </li>
      <li>
        <h4 className="hours">{hours}</h4>
        <span>Hour</span>
      </li>
      <li>
        <h4 className="minutes">{minutes}</h4>
        <span>Min</span>
      </li>
      <li>
        <h4 className="seconds">{seconds}</h4>
        <span>Sec</span>
      </li>
    </ul>
  );
};
